import React from 'react';
import PropTypes from 'prop-types';
import pickup1 from '../../assets/pickup-1.svg';
import dropOff1 from '../../assets/drop-off-1.svg';
import dropOff2 from '../../assets/drop-off-2.svg';
import dropOff3 from '../../assets/drop-off-3.svg';
import dropOff4 from '../../assets/drop-off-4.svg';
import dropOff5 from '../../assets/drop-off-5.svg';
import dropOff6 from '../../assets/drop-off-6.svg';
import dropOff7 from '../../assets/drop-off-7.svg';
import dropOff8 from '../../assets/drop-off-8.svg';
import dropOff9 from '../../assets/drop-off-9.svg';
import dropOff10 from '../../assets/drop-off-10.svg';
import uploadLogo from '../../assets/upload-logo.svg';
import uploadPhoto from '../../assets/upload-photo.svg';
import truck from '../../assets/truck.svg';
import uploadFile from '../../assets/placeholder/dragger.svg';

const PlaceholderImage = props => {
  const { alt, className, image, style } = props;

  // prettier-ignore
  const images = {
    pickup1, dropOff1, dropOff2, dropOff3, dropOff4, dropOff5,
    dropOff6, dropOff7, dropOff8, dropOff9, dropOff10, uploadLogo,
    uploadPhoto, truck, uploadFile
  };

  return (
    <img
      src={images[image]}
      alt={alt ?? ''}
      className={className}
      style={style}
    />
  );
};

PlaceholderImage.defaultProps = {
  image: 'dropOff1'
};

PlaceholderImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.string,
  style: PropTypes.object
};

export default PlaceholderImage;
