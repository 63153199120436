import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    checked: PropTypes.bool,
    content: PropTypes.node,
    disabled: PropTypes.bool,
    dividerBefore: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    id: PropTypes.string.isRequired,
    testId: PropTypes.string,
    title: PropTypes.node,
    value: PropTypes.string.isRequired
  })
);
