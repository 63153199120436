import React from 'react';
import PropTypes from 'prop-types';
import Select from '../Select/Select';

// Takes a nested object containing items with name, id, and children and converts
// into a flat array with indentation signifying the tree depth
const flattenTree = (rootOrg, disableGroups, disableBranches, excludeIds) => {
  const TAB_SIZE = 6;
  let items = [];

  const orgLevel = (org, depth) => {
    // If this org is in the list of orgs to exclude, do nothing and don't render its children
    if (excludeIds.includes(org.id)) return;

    // \xa0 is a non-breaking space character
    // only display tab characters if the depth is > 0, subtract one tab so items at depth==1 are left-aligned
    const tab = '\xa0'.repeat(depth > 0 ? (depth - 1) * TAB_SIZE : 0);
    const obj = {
      id: org.id,
      name: `${tab}${org.name}`
    };

    const shouldAddItem = () => {
      // Don't display the root of the tree
      if (depth <= 0) return false;
      if (org.group) {
        if (disableGroups && !org.children) {
          return false;
        } else {
          return true;
        }
      } else {
        if (disableBranches) {
          return false;
        } else {
          return true;
        }
      }
    };

    if (disableGroups && org.children) {
      obj.disabled = true;
    }

    if (shouldAddItem()) {
      items.push(obj);
    }

    if (org.children) {
      depth++;
      org.children.forEach(o => {
        orgLevel(o, depth);
      });
    }
  };

  orgLevel(rootOrg, 0);
  return items;
};

const BranchesInput = props => {
  const selectOptions = flattenTree(
    props.tree,
    props.disableGroups,
    props.disableBranches,
    props.excludeIds
  );

  if (props.allowBlank) {
    selectOptions.unshift({ id: '', name: '- none -' });
  }

  return <Select {...props} options={selectOptions} />;
};

BranchesInput.defaultProps = {
  allowBlank: false,
  disableGroups: false,
  disableBranches: false,
  excludeIds: [],
  showRequiredIndicator: true
};

BranchesInput.propTypes = {
  customErrorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  hasErrors: PropTypes.bool,
  helpText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
      name: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  testId: PropTypes.string,
  tree: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    org_type: PropTypes.oneOf(['group', 'branch']),
    children: PropTypes.array
  }).isRequired,
  allowBlank: PropTypes.bool,
  disableGroups: PropTypes.bool,
  disableBranches: PropTypes.bool,
  excludeIds: PropTypes.arrayOf(PropTypes.number),
  value: PropTypes.string
};

export default BranchesInput;
