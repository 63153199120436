import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

const CheckboxBlock = props => {
  const { checked, helpText, id, label, name, onChange, disabled } = props;
  return (
    <>
      <Checkbox
        checked={checked}
        id={id}
        label={label}
        name={name}
        onChange={onChange}
        disabled={disabled}
      />
      {helpText && <div className="help-block mt-2 ml-10">{helpText}</div>}
    </>
  );
};

CheckboxBlock.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  helpText: PropTypes.node
};

export default CheckboxBlock;
