import React from 'react';
import PropTypes from 'prop-types';
import mapPinPickupV2 from '../../assets/mapPinPickupV2.png';
import mapPinDropOffV2 from '../../assets/mapPinDropOffV2.png';
import mapPinPickup from '../../assets/mapPinPickup.png';
import mapPinDropOff from '../../assets/mapPinDropOff.png';
import mapPinDriver from '../../assets/mapPinDriver.png';

const MapPin = props => {
  const { className, children, type } = props;

  switch (type) {
    case 'pickupV2':
      return (
        <img
          src={mapPinPickupV2}
          alt="Pickup Location"
          className={className}
          style={{
            width: 47,
            height: 73,
            transformOrigin: 'left bottom',
            transform: 'translate(calc(-50% + 2px) , calc(-100% + 8px))'
          }}
        />
      );
    case 'dropOffV2':
      return (
        <img
          src={mapPinDropOffV2}
          alt="Drop-Off Location"
          className={className}
          style={{
            width: 47,
            height: 73,
            transformOrigin: 'left bottom',
            transform: 'translate(calc(-50% + 2px) , calc(-100% + 8px))'
          }}
        />
      );
    case 'pickup':
      return (
        <img
          src={mapPinPickup}
          alt="Pickup Location"
          className={className}
          style={{
            width: 42,
            height: 68,
            transformOrigin: 'right bottom',
            transform: 'translate(-38px, -100%)'
          }}
        />
      );
    case 'dropOff':
      return (
        <img
          src={mapPinDropOff}
          alt="Drop-Off Location"
          className={className}
          style={{
            width: 42,
            height: 68,
            transformOrigin: 'left bottom',
            transform: 'translate(-4px, -100%)'
          }}
        />
      );
    case 'driver':
      return (
        <img
          src={mapPinDriver}
          alt="Driver Location"
          className={className}
          style={{
            width: 42,
            height: 68,
            transformOrigin: 'left bottom',
            transform: 'translate(-4px, -100%)'
          }}
        />
      );
    case 'circle':
      return (
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse
            cx="7.99999"
            cy="7.18182"
            rx="7.18182"
            ry="7.18182"
            fill="#0072ED"
          />
        </svg>
      );
    default:
      return <div>{children}</div>;
  }
};

MapPin.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string
};

export default MapPin;
