import React from 'react';
import PropTypes from 'prop-types';

const TableHeaderCell = props => {
  const { children } = props;

  return <th>{children}</th>;
};

TableHeaderCell.propTypes = {
  children: PropTypes.node
};

export default TableHeaderCell;
