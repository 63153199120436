import React from 'react';
import PropTypes from 'prop-types';

const Table = props => {
  const { children, thead } = props;

  return (
    <div className="table-responsive table-sortable">
      <table className="table pretty table-hover">
        <thead>{thead}</thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  children: PropTypes.node,
  thead: PropTypes.node
};

export default Table;
