import React from 'react';
import PropTypes from 'prop-types';

const FormRow = props => {
  const { children, className, customClass, error } = props;

  return (
    <div
      className={`mb-8 last:mb-0 ${
        error ? 'ring-1 ring-red-500 ring-offset-8' : ''
      }  ${(className || customClass) ?? ''}`}
    >
      {children}
      {error && <div className="mt-8 text-red-500">{error}</div>}
    </div>
  );
};

FormRow.defaultProps = {
  size: 'md'
};

FormRow.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  customClass: PropTypes.string, // customClass prop is deprecated; use className instead
  error: PropTypes.string
};

export default FormRow;
