import React from 'react';
import PropTypes from 'prop-types';

const Glyphicon = props => {
  const { name } = props;

  return <span className={`glyphicon glyphicon-${name}`}></span>;
};

Glyphicon.propTypes = {
  name: PropTypes.string.isRequired
};

export default Glyphicon;
