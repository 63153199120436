import React from 'react';
import PropTypes from 'prop-types';
import MapPin from '../MapPin/MapPin';

const GoogleMapWrapper = props => {
  const {
    className,
    adjustmentPin,
    aspectRatio,
    isRepositioning,
    children
  } = props;

  return (
    <div
      className={`embed-responsive embed-responsive-${aspectRatio} ${className}`}
    >
      <div className="embed-responsive-item relative">
        {isRepositioning && adjustmentPin && (
          <MapPin
            type={adjustmentPin}
            className="pointer-events-none absolute top-1/2 left-1/2 z-10"
          />
        )}
        {children}
      </div>
    </div>
  );
};

GoogleMapWrapper.defaultProps = {
  aspectRatio: '16by9'
};

GoogleMapWrapper.propTypes = {
  className: PropTypes.string,
  adjustmentPin: PropTypes.string,
  aspectRatio: PropTypes.string,
  isRepositioning: PropTypes.bool,
  children: PropTypes.node
};

export default GoogleMapWrapper;
