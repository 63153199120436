import React from 'react';
import PropTypes from 'prop-types';

const TableCell = props => {
  const { className, colSpan, children } = props;

  return (
    <td className={className} colSpan={colSpan}>
      {children}
    </td>
  );
};

TableCell.propTypes = {
  className: PropTypes.string,
  colSpan: PropTypes.string,
  children: PropTypes.node
};

export default TableCell;
