import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import MapPin from '../MapPin/MapPin';
import tailwindConfig from '../../../../../tailwind.config';

const FIT_BOUNDS_PADDING = { top: 75, left: 30, right: 30, bottom: 20 }; // numbers are supposedly pixels according to the google maps docs
const DEFAULT_CENTER = { lat: 44.955871, lng: -93.177222 };
const DEFAULT_ZOOM = 11;
const BRAND_BLUE = tailwindConfig.theme.extend.colors.blue['500'];

const GoogleMap = props => {
  const {
    center,
    directionPins,
    googleApiKey,
    isRepositioning,
    mapOptions,
    maxZoom,
    onDragend,
    onMapsInit,
    pins,
    polygonBoundaries,
    showMapTypeControl,
    showTraffic,
    zoom,
    radiusKm
  } = props;

  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);
  const [mapPolygon, setMapPolygon] = useState(null);
  const [mapCircle, setMapCircle] = useState(null);

  useEffect(() => {
    if (mapInstance && mapsInstance && mapPolygon && polygonBoundaries) {
      // update polygon shape
      mapPolygon.setOptions({ path: polygonBoundaries });

      // update map bounds around polygon
      const bounds = new google.maps.LatLngBounds();
      mapPolygon.getPath().forEach(element => bounds.extend(element));
      mapInstance.fitBounds(bounds, FIT_BOUNDS_PADDING);

      // Resets the zoom level to 16 if it's higher by default after fitBounds() occurs.
      // For example, if there is only one point
      const listener = mapsInstance.event.addListener(
        mapInstance,
        'idle',
        () => {
          if (mapInstance.getZoom() > maxZoom) mapInstance.setZoom(maxZoom);
          google.maps.event.removeListener(listener);
        }
      );
    }
  }, [mapPolygon, mapInstance, mapsInstance, polygonBoundaries]);

  useEffect(() => {
    if (mapInstance && mapsInstance && !polygonBoundaries) {
      handleLocationChange(mapInstance, mapsInstance, pins);
      getDirections(mapInstance, mapsInstance, pins);
    }
  }, [mapInstance, mapsInstance, pins, polygonBoundaries]);

  const getMapBounds = (map, maps, pins) => {
    const bounds = new maps.LatLngBounds();

    pins.forEach(pin => {
      bounds.extend(new maps.LatLng(pin.lat, pin.lng));
    });

    return bounds;
  };

  useEffect(() => {
    if (mapInstance && mapsInstance && mapCircle && radiusKm && center) {
      // update circle radius
      mapCircle.setOptions({
        radius: radiusKm * 1000,
        visible: true,
        center: center
      });

      // update map bounds around circle
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(mapCircle.getBounds().getNorthEast());
      bounds.extend(mapCircle.getBounds().getSouthWest());
      mapInstance.fitBounds(bounds, FIT_BOUNDS_PADDING);
    }
  }, [radiusKm, mapInstance, mapInstance, mapCircle, center]);

  const handleLocationChange = (map, maps, pins) => {
    if (showTraffic) {
      const trafficLayer = new maps.TrafficLayer();
      trafficLayer.setMap(map);
    }

    if (showMapTypeControl) {
      map.mapTypeControl = true;
    }

    if (!pins) return;

    const bounds = getMapBounds(map, maps, pins);
    map.fitBounds(bounds, FIT_BOUNDS_PADDING);

    // Resets the zoom level to 16 if it's higher by default after fitBounds() occurs.
    // For example, if there is only one point
    const listener = maps.event.addListener(map, 'idle', () => {
      if (map.getZoom() > maxZoom) map.setZoom(maxZoom);
      google.maps.event.removeListener(listener);
    });
  };

  const getDirectionWaypointsFromPins = (pins, waypointNames) => {
    return waypointNames.map(waypoint => {
      const waypointPin = pins.find(pin => pin.key === waypoint);
      return { lat: waypointPin.lat, lng: waypointPin.lng };
    });
  };

  const getDirections = (map, maps, pins) => {
    if (!directionPins || !directionPins.length) return;

    // get an array of {lat, lng} coordinates corresponding to the directionPins prop
    const directionWaypoints = getDirectionWaypointsFromPins(
      pins,
      directionPins
    );

    // If for some reason there are 0 or 1 waypoints, exit
    if (!directionWaypoints || directionWaypoints.length <= 1) return;

    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer({
      suppressMarkers: true
    });

    const startPin = directionWaypoints.shift();
    const endPin = directionWaypoints.pop();

    const request = {
      origin: new maps.LatLng(startPin.lat, startPin.lng),
      destination: new maps.LatLng(endPin.lat, endPin.lng),
      waypoints: directionWaypoints.map(wpt => ({
        location: new maps.LatLng(wpt.lat, wpt.lng)
      })),
      optimizeWaypoints: false,
      travelMode: 'DRIVING'
    };

    directionsRenderer.setOptions({
      polylineOptions: {
        strokeColor: BRAND_BLUE,
        strokeWeight: '6',
        strokeOpacity: '0.8'
      }
    });
    directionsRenderer.setMap(map);
    directionsService
      .route(request)
      .then(response => {
        directionsRenderer.setDirections(response);
      })
      // eslint-disable-next-line no-unused-vars
      .catch(e => {
        // leaving here for future debugging
        // console.log(e);
      });
  };

  return (
    <GoogleMapReact
      center={center}
      zoom={zoom}
      defaultCenter={DEFAULT_CENTER}
      defaultZoom={DEFAULT_ZOOM}
      bootstrapURLKeys={{
        key: googleApiKey
      }}
      yesIWantToUseGoogleMapApiInternals
      options={mapOptions}
      onGoogleApiLoaded={({ map, maps }) => {
        setMapInstance(map);
        setMapsInstance(maps);
        if (onMapsInit) {
          onMapsInit(map, maps);
        }
        if (onDragend) {
          // using the 'idle' event here allows position
          // to be reported after inertia scrolling finishes
          maps.event.addListener(map, 'idle', () => {
            onDragend(map, maps);
          });
        }

        if (polygonBoundaries) {
          const polygon = new maps.Polygon({
            paths: [center],
            editable: false,
            draggable: false,
            strokeColor: BRAND_BLUE,
            fillColor: BRAND_BLUE,
            map: map
          });
          setMapPolygon(polygon);
        }

        const circle = new maps.Circle({
          center: center,
          radius: radiusKm * 1000,
          map: map,
          options: {
            strokeColor: BRAND_BLUE,
            fillColor: BRAND_BLUE
          },
          visible: radiusKm > 0
        });
        setMapCircle(circle);
      }}
    >
      {!isRepositioning &&
        pins?.map(pin => {
          return (
            <MapPin
              key={pin.key || `lat${pin.lat}lng${pin.lng}`}
              type={pin.type}
              lat={pin.lat}
              lng={pin.lng}
            />
          );
        })}
    </GoogleMapReact>
  );
};

GoogleMap.defaultProps = {
  mapOptions: {},
  maxZoom: 16,
  showMapTypeControl: true
};

GoogleMap.propTypes = {
  googleApiKey: PropTypes.string,
  center: PropTypes.shape({
    lat: PropTypes.any,
    lng: PropTypes.any
  }),
  // An ordered array of strings or numbers corresponding to the `key` of pins in the `pins` prop
  directionPins: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  isRepositioning: PropTypes.bool,
  mapOptions: PropTypes.object,
  maxZoom: PropTypes.number,
  onMapsInit: PropTypes.func,
  onDragend: PropTypes.func,
  pins: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      lat: PropTypes.any,
      lng: PropTypes.any,
      type: PropTypes.string
    })
  ),
  polygonBoundaries: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.any,
      lng: PropTypes.any
    })
  ),
  showMapTypeControl: PropTypes.bool,
  showTraffic: PropTypes.bool,
  zoom: PropTypes.number,
  radiusKm: PropTypes.number
};

// Prevent unnecessary API calls by checking if no props have changed.
// If props are all the same this component shouldn't update.
// JSON.stringify doesn't include functions so in the future if a prop is
// added which is a function, you may also need to include it in this conditional
export default React.memo(GoogleMap, (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) {
    return true;
  } else {
    return false;
  }
});
