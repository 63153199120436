import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import Tooltip from '../../components/Tooltip/Tooltip';
import Icon from '../Icon';

const Select = props => {
  const {
    customErrorMessage,
    id,
    hasErrors,
    helpText,
    label,
    name,
    placeholder,
    onChange,
    onInvalid,
    options,
    required,
    showRequiredIndicator,
    testId,
    value,
    tooltipText,
    disabled
  } = props;

  const [isInvalid, setIsInvalid] = useState(hasErrors);
  const [fieldValue, setFieldValue] = useState(value ?? '');

  useUpdateEffect(() => {
    if (value === null || typeof value === 'undefined') {
      setFieldValue('');
    } else {
      setIsInvalid(false);
      setFieldValue(value);
    }
  }, [value]);

  const handleInvalid = e => {
    if (customErrorMessage) {
      e.preventDefault();
    }
    setIsInvalid(true);

    if (onInvalid) {
      onInvalid(e);
    }
  };

  const handleBlur = e => {
    e.target.checkValidity();
  };

  const handleChange = e => {
    if (onChange) onChange(e);

    setIsInvalid(false);
    setFieldValue(e.target.value);
  };

  return (
    <div
      className={`mb-6 last:mb-0 ${isInvalid ? 'has-error' : ''}`}
      data-testid={testId ? `${testId}-container` : null}
    >
      <label htmlFor={id}>
        {required && showRequiredIndicator && (
          <>
            <abbr title="This field is required.">*</abbr>{' '}
          </>
        )}
        {label}
        {showRequiredIndicator === false && !required && (
          <span
            className="text-muted ml-2"
            style={{ fontWeight: 'normal', fontSize: '.85em' }}
          >
            {'(optional)'}
          </span>
        )}
        {tooltipText && (
          <Tooltip text={tooltipText}>
            <span className="inline-block" style={{ verticalAlign: -2 }}>
              <Icon name="help" />
            </span>
          </Tooltip>
        )}
      </label>
      <select
        className="form-control"
        id={id}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        onInvalid={handleInvalid}
        required={required}
        value={fieldValue}
        data-testid={testId ? `${testId}-select` : null}
        disabled={disabled}
      >
        {placeholder ? (
          <option value="" disabled>
            {placeholder}
          </option>
        ) : (
          <option value=""></option>
        )}
        {options?.map(option => (
          <option
            key={`${option.id}${option.name}`}
            value={option.id}
            disabled={option.disabled}
          >
            {option.name}
          </option>
        ))}
      </select>
      {isInvalid && customErrorMessage && (
        <div
          className="help-block error-message"
          data-testid={testId ? `${testId}-errormessage` : null}
        >
          {customErrorMessage}
        </div>
      )}
      {helpText && (
        <div
          className="help-block"
          data-testid={testId ? `${testId}-helptext` : null}
        >
          {helpText}
        </div>
      )}
    </div>
  );
};

Select.defaultProps = {
  showRequiredIndicator: true
};

Select.propTypes = {
  customErrorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  hasErrors: PropTypes.bool,
  helpText: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onInvalid: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.node,
      name: PropTypes.string
    })
  ),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string,
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool
};

export default Select;
