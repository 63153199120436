import React from 'react';
import { dateInputType } from '../../types/dateTimeDataType';
import DateTimeInput from '../DateTimeInput/DateTimeInput';

const DateInput = props => {
  const { dateFormat, placeholder, ...remainingProps } = props;

  return (
    <DateTimeInput
      dateFormat={dateFormat || 'M/d/yyyy'}
      placeholder={placeholder || 'mm/dd/yyyy'}
      {...remainingProps}
    />
  );
};

DateInput.defaultProps = {
  disabled: false,
  showRequiredIndicator: true
};

DateInput.propTypes = {
  ...dateInputType
};

export default DateInput;
