import PropTypes from 'prop-types';

const sharedTypes = {
  alternateValue: PropTypes.string,
  autoComplete: PropTypes.string,
  customErrorMessage: PropTypes.string,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  helpText: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  invisibleLabel: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInvalid: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredIndicator: PropTypes.bool,
  testId: PropTypes.string,
  units: PropTypes.node,
  value: PropTypes.string
};

const uniqueDateTypes = {
  minDate: PropTypes.instanceOf(Date)
};

const uniqueTimeTypes = {
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeCaption: PropTypes.string,
  timeFormat: PropTypes.string,
  timeIntervals: PropTypes.number
};

export const dateInputType = {
  ...sharedTypes,
  ...uniqueDateTypes
};

export const timeInputType = {
  ...sharedTypes,
  ...uniqueTimeTypes
};

const dateTimeInputType = {
  ...sharedTypes,
  ...dateInputType,
  ...timeInputType
};

export default dateTimeInputType;
