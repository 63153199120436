import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import radioCardsDataType from '../../types/radioCardsDataType';
import { getDefaultCheckedItem } from '../../utils';

const RadioButtons = props => {
  const {
    bodyClassName,
    bodyContent,
    options,
    customErrorMessage,
    hasErrors,
    helpText,
    legendClassName,
    name,
    onChange,
    required,
    staticContent,
    testId,
    value,
    legend,
    fieldSetClassName
  } = props;

  const [isInvalid, setIsInvalid] = useState(hasErrors);

  const [checkedItemId, setCheckedItemId] = useState(
    getDefaultCheckedItem(options, value)
  );

  useEffect(() => {
    setCheckedItemId(getDefaultCheckedItem(options, value));
  }, [value]);

  const handleInvalid = e => {
    if (customErrorMessage) {
      e.preventDefault();
      setIsInvalid(true);
    }
  };

  const handleChange = e => {
    if (onChange) onChange(e);

    setCheckedItemId(e.target.id);
    setIsInvalid(false);
  };

  return (
    <fieldset
      className={`${isInvalid ? 'has-error' : ''} ${fieldSetClassName}`}
    >
      {legend && (
        <legend className={`legend-label ${legendClassName}`}>{legend}</legend>
      )}
      {options.map(option => {
        return (
          <label
            key={option.id}
            htmlFor={option.id}
            className={`radio-button ${option.className ?? ''}`}
          >
            <input
              id={option.id}
              type="radio"
              name={name}
              className="radio-button__input"
              value={option.value}
              required={required}
              onChange={handleChange}
              onInvalid={handleInvalid}
              checked={option.id === checkedItemId}
              data-testid={option.testId ?? null}
            />
            <span className="radio-button__label">{option.name}</span>
          </label>
        );
      })}
      {bodyContent?.[checkedItemId] && (
        <div
          className={bodyClassName ?? 'mt-6'}
          data-testid={testId ? `${testId}-bodyContent` : null}
        >
          {bodyContent[checkedItemId]}
        </div>
      )}
      {staticContent}
      {customErrorMessage && isInvalid && (
        <div className="help-block">{customErrorMessage}</div>
      )}
      {helpText}
    </fieldset>
  );
};

RadioButtons.propTypes = {
  bodyClassName: PropTypes.string,
  bodyContent: PropTypes.objectOf(PropTypes.node),
  options: radioCardsDataType.isRequired,
  customErrorMessage: PropTypes.node,
  hasErrors: PropTypes.bool,
  helpText: PropTypes.node,
  legendClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  staticContent: PropTypes.node,
  testId: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  legend: PropTypes.node,
  fieldSetClassName: PropTypes.string
};

export default RadioButtons;
